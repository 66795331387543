/* Container */
.ceremony-container {
    padding: 20px;
    text-align: center;
    margin-top: 60px;
}

/* Banner Slider */
.ceremony-slider {
    width: 100%;
    max-width: 1200px;
    margin-top: 30px;
    margin-bottom: 50px;
    overflow: hidden;
    border-radius: 10px;
}

.slider-image {
    width:100%;
    height: 500px;
    border-radius: 10px;
    object-fit: cover;
}

/* Events Heading */
.events-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Cards Container */
.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Card */
.card {
    width: 300px;
    height: 420px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card:hover {
    transform: scale(1.05);
}

/* Card Image */
.card-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

/* Card Content */
.card-content {
    padding: 15px;
}

.card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: black;
}

.card-date {
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
}

.card-description {
    font-size: 14px;
    color: #555;
}

/* Explore Button */
.explore-button {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.explore-button:hover {
    background-color: #0056b3;
}

