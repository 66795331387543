/* Ceremony.module.css */
.inagurationHeader{
    width: 90%;
    margin: auto;
}
.ceremony-container {
    padding: 20px;
    text-align: center;
    margin-top: 60px;
    line-height: 20px;
}

.ceremony-header {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-bottom: 2px solid #ddd;
}

.ceremony-title {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
}

.ceremony-subtitle {
    font-size: 18px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
}

.ceremony-date {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.ceremony-Sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.ceremony-gallery {
    padding: 20px;
}

.ceremony-gallery-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 15px;
    padding: 10px;
}

.ceremony-gallery-images img {
    width:100%;
    height: 175px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.ceremony-gallery-images img:hover {
    transform: scale(1.05);
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: white;
    padding: 15px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    border-radius: 10px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 25px;
    font-weight: bold;
    color: white;
    background: red;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 50%;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .ceremony-title {
        font-size: 24px;
    }

    .ceremony-subtitle {
        font-size: 16px;
    }

    .ceremony-date {
        font-size: 14px;
    }

    .modal-content {
        padding: 10px;
    }

    .close-btn {
        font-size: 20px;
        padding: 5px 8px;
    }
}
@media (max-width: 480px) {
    .ceremony-container {
        padding: 10px;
    }

    .ceremony-title {
        font-size: 20px;
    }

    .ceremony-subtitle {
        font-size: 14px;
    }

    .ceremony-date {
        font-size: 12px;
    }

    .ceremony-gallery-images {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Increased from 150px to 180px */
        gap: 12px; /* Slightly increased gap for better spacing */
    }
}
/* Banner Slider */
.ceremony-slider {
    width: 100%;
    max-width: 1200px; /* Adjust width as needed */
    margin-top: 30px;
    margin-bottom: 50px;
    overflow: hidden;
    border-radius: 10px;
}

.slider-image {
    width: 100%;
    /* max-width: 1200px; */
    height: 400px;
    border-radius: 10px;
}

/* Swiper Pagination Dots */
.swiper-pagination-bullet {
    background-color: white;
    width: 10px;
    height: 10px;
    opacity: 0.8;
}

.swiper-pagination-bullet-active {
    background-color: #ffcc00; /* Highlight active dot */
    opacity: 1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .slider-image {
        height: 250px;
    }
}

@media (max-width: 480px) {
    .slider-image {
        height: 200px;
    }

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
    }
}
