/* Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #000;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.navbarLogo .logologo {
  margin-left: 20px;
  height: 48px;
  cursor: pointer;
}

.logoText {
  height: 50px;
  filter: invert(1);
  cursor: pointer;
}

.navbarLinks {
  display: flex;
  list-style: none;
}

.navbarLinks li {
  margin: 0 1rem;
}

.navbarLinks a,
.eventBtn {
  color: #ddd;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
}



.eventBtn:hover {
  color: #fff;
}

/* Modal Styling */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
  color: black;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Navbar Links Underline Animation */
.navbarLinks a {
  color: #ddd;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  position: relative;
}

.navbarLinks a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: width 0.3s ease;
}

.navbarLinks a:hover::after {
  width: 100%;
}

/* Optional: To make the text color change on hover */
.navbarLinks a:hover {
  color: #fff;
}
